import {Injectable} from '@angular/core';
import {BlRequestService} from '../../support/blRequest.service';
import {Observable} from 'rxjs';

@Injectable()
export class BlCompoundService {

    constructor(private  blRequestService: BlRequestService) {
    }

    basePath = '/compound';

    getBySampleSourceNameAndSampleTypeName(sampleSourceName, sampleTypeName): Observable<any> {
        const path = `${this.basePath}/${sampleSourceName}/${sampleTypeName}`;
        return this.blRequestService.get(path);
    }
}
