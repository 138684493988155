import {Injectable} from '@angular/core';
import {BlRequestService} from '../../support/blRequest.service';
import {ShippingInformationDTO} from "../../models/UpdateBatchRequestTypes";

@Injectable()
export class BlShippingInformationService {

    basePath = '/shipping';

    constructor(private blRequestService: BlRequestService) {
    }

    public build(sampleID: string, shippingInformation: ShippingInformationDTO, workspace?: string) {
        let workspaceFilter = '';
        if (workspace) {
            workspaceFilter = `?workspaceId=${workspace}`;
        }

        const path = `${this.basePath}/${sampleID}${workspaceFilter}`;
        // TODO: Return this to normal after testing
        console.log("SEND SHIPPING INFO:\m" +
            "url: " + path +
            "data: " + JSON.stringify(shippingInformation));
        return this.blRequestService.post(path, shippingInformation);
    }

}
