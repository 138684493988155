import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import slugify from 'slugify';

export interface SampleSDSConfig {
    sampleType: string,
    sdsURL: string
}

@Injectable()
export class BlSampleSDSService {

    sampleSDSConfigs: SampleSDSConfig[] = [];

    constructor(private http: HttpClient) {
        this.loadSDS().subscribe(sampleTypes => {
                this.sampleSDSConfigs = sampleTypes;
            }
        );
    }

    private loadSDS(): Observable<SampleSDSConfig[]> {
        const sdsConfigURL = `${environment.bucketUrl}${environment.configSDSPath}`;
        const headers = {};
        headers['Accept'] = 'application/json';
        return this.http.get<SampleSDSConfig[]>(sdsConfigURL, {headers: new HttpHeaders(headers)});
    }

    public getSampleSDSURL(sampleType: string): string {
        let sampleSDSURL = this.getSampleSDSURLFromLoadedConfig(sampleType)

        if (!sampleSDSURL) {
            sampleSDSURL = this.getSampleSDSURLFromLegacyFormat(sampleType);
        }

        return sampleSDSURL;
    }

    private getSampleSDSURLFromLoadedConfig(sampleType): string| undefined {
        const sampleTypeFormatted = this.formatSampleTypeName(sampleType);
        if (this.sampleSDSConfigs) {
            const sampleSDSConfig = this.sampleSDSConfigs.find(sdsConfig => sdsConfig.sampleType === sampleTypeFormatted);
            if (sampleSDSConfig) {
                return sampleSDSConfig.sdsURL;
            }
        }
        return undefined;
    }

    private getSampleSDSURLFromLegacyFormat(sampleType: string): string {
        const sampleTypeFormatted = this.formatSampleTypeName(sampleType);
        return `${environment.bucketUrl}${environment.sdsFolder}/${sampleTypeFormatted}.pdf`;
    }

    private formatSampleTypeName(sampleTypeName: string): string {
        var sampleTypeFormatted = sampleTypeName.replace('/', '-').replace('(', '').replace(')', '');
        sampleTypeFormatted = slugify(sampleTypeFormatted);
        sampleTypeFormatted = sampleTypeFormatted.toUpperCase();
        return sampleTypeFormatted;
    }
}

