import {Injectable} from '@angular/core';
import {BlRequestService} from '../../support/blRequest.service';
import {LabwareInfoDTO} from "../../models/UpdateBatchRequestTypes";

@Injectable()
export class BlLabwareInformationService {

    basePath = '/labware';

    constructor(private blRequestService: BlRequestService) {
    }

    public build(sampleID: string, labwareInformation: LabwareInfoDTO, workspace?: string) {
        let workspaceFilter = '';
        if (workspace) {
            workspaceFilter = `?workspaceId=${workspace}`;
        }

        const path = `${this.basePath}/${sampleID}${workspaceFilter}`;
        // TODO: Return this to normal after testing
        console.log("SEND LABWARE INFO:\m" +
            "url: " + path +
            "data: " + JSON.stringify(labwareInformation));
        // return of(null);
        return this.blRequestService.post(path, labwareInformation);
    }

}
