import {Injectable} from '@angular/core';
import {BlRequestService} from '../../support/blRequest.service';
import {BinaryFileEncodedDTO} from '../../models/BinaryFileEncodedDTO';
import {ResultFileInfoDTO} from "../../models/ResultFileInfoDTO";
import {Observable} from 'rxjs';

@Injectable()
export class BlFileStorageService {

    basePath = '/files';

    constructor(private blRequestService: BlRequestService) {
    }

    public downloadResultsFile(sample: string, file: ResultFileInfoDTO, workspace?: string) {
        let workspaceFilter = '';
        if (workspace) {
            workspaceFilter = `?workspaceId=${workspace}`;
        }

        const path = `${this.basePath}/${sample}/results/${file.id}${workspaceFilter}`;
        return this.blRequestService.getFile(path);
    }

    public downloadStageFile(stageId: string, workspace?: string) {
        let workspaceFilter = '';
        if (workspace) {
            workspaceFilter = `?workspaceId=${workspace}`;
        }
        const path = `${this.basePath}/${stageId}${workspaceFilter}`;
        return this.blRequestService.getFile(path);
    }


    public uploadStageFile(sample: string, stageState: string, workspace?: string, binaryFileEncodedDTO?: BinaryFileEncodedDTO): Observable<string[]> {
        let workspaceFilter = '';
        if (workspace) {
            workspaceFilter = `?workspaceId=${workspace}`;
        }

        const path = `${this.basePath}/${sample}/stages/${stageState}${workspaceFilter}`;
        // TODO: Return this to normal after testing
        console.log("SEND STAGE FILE INFO:\m" +
            "url: " + path +
            "data: " + JSON.stringify(binaryFileEncodedDTO));
        // const fileURL = `https://storage.googleapis.com/private-fb-app-marcoapps-stage/blocklabs/stages/${sample}.txt`;
        // return of([fileURL, "fileHash"]);
        return this.blRequestService.post<string[]>(path, binaryFileEncodedDTO);
    }

}
