import {Injectable} from '@angular/core';
import {BlResultsService} from './blResultsService.service';
import * as FileSaver from 'file-saver';

@Injectable()
export class BlReportService {

    constructor(private blResultsService: BlResultsService) {
    }

    downloadReportFile(sample, workspace) {
        this.blResultsService.downloadReport(sample, workspace).subscribe(
            function (data) {
                const file = new Blob([data], {type: 'application/pdf'});
                FileSaver.saveAs(file, sample + '_report');
            }
        );
    }
}
