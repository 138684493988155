import {Injectable} from '@angular/core';
import {BlRequestService} from '../../support/blRequest.service';
import {Observable} from "rxjs";
import {BinaryFileEncodedDTO} from "../../models/BinaryFileEncodedDTO";

@Injectable()
export class BlResultsService {

    basePath = '/results';

    constructor(private blRequestService: BlRequestService) {
    }

    public uploadResults(sample: string,  binaryFilesList: { binaryFiles: BinaryFileEncodedDTO[] }, workspace?: string,) {
        let workspaceFilter = '';
        if (workspace) {
            workspaceFilter = `?workspaceId=${workspace}`;
        }

        const path = `${this.basePath}/multiple/${sample}${workspaceFilter}`;
        // TODO: Return this to normal after testing
        console.log("SEND RESULTS INFO:\m" +
            "url: " + path +
            "data: " + JSON.stringify(binaryFilesList));
        // return of(null)
        return this.blRequestService.post(path, binaryFilesList);
    }

    public downloadReport(sample: string, workspace: string) {
        let workspaceFilter = '';
        if (workspace != null) {
            workspaceFilter = `?workspaceId=${workspace}`;
        }

        const path = `${this.basePath}/report/${sample}${workspaceFilter}`;
        return this.blRequestService.getFile(path);
    }

    public getResultFiles(sample: string, workspace?: string): Observable<any> {
        let workspaceFilter = '';
        if (workspace) {
            workspaceFilter = `?workspaceId=${workspace}`;
        }

        const path = `${this.basePath}/files/${sample}${workspaceFilter}`;
        return this.blRequestService.get(path);
    }

}
